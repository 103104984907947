import Parse from 'parse';

export const BackgroundGroup = Parse.Object.extend('BackgroundGroup');

// not use function actionWithLoader because loading is not necessary

export const createBackgroundGroup = (values, hasParent = false) => {
  return async (dispatch, getState) => {
    const backgroundGroup = new BackgroundGroup();
    backgroundGroup.set('name', values.name);
    if (hasParent) {
      const parent = new BackgroundGroup();
      parent.id = values.parent;
      backgroundGroup.set('parent', parent);
    }
    await backgroundGroup.save();

    dispatch({
      type: hasParent ? 'ADD_BACKGROUND_SUBGROUP' : 'ADD_BACKGROUND_GROUP',
      payload: backgroundGroup.toJSON(),
    });

    return backgroundGroup.toJSON();
  };
};

export const getBackgroundGroups = (hasParent = false) => {
  return async (dispatch, getState) => {
    const query = new Parse.Query('BackgroundGroup').ascending('order');
    if (hasParent) {
      query.include('parent').exists('parent');
    } else {
      query.doesNotExist('parent');
    }
    const backgroundGroups = await query.find();

    dispatch({
      type: hasParent ? 'GET_BACKGROUND_SUBGROUPS' : 'GET_BACKGROUND_GROUPS',
      [hasParent ? 'backgroundSubgroups' : 'backgroundGroups']: backgroundGroups.map((group) => group.toJSON()),
    });
  };
};

export const getBackgroundGroupsByParent = (parentId) => {
  return async (dispatch, getState) => {
    if (!parentId) return;
    const parent = new BackgroundGroup();
    parent.id = parentId;

    const backgroundGroups = await new Parse.Query(BackgroundGroup).equalTo('parent', parent).ascending('order').find();

    dispatch({
      type: 'GET_BACKGROUND_SUBGROUPS',
      backgroundSubgroups: backgroundGroups.map((group) => group.toJSON()),
    });
  };
};

export const reorderBackgroundGroups = (values, hasParent) => {
  return async (dispatch, getState) => {
    const promises = values.map((group, index) => {
      const backgroundGroup = new BackgroundGroup();
      backgroundGroup.id = group.id;
      backgroundGroup.set('order', index);
      return backgroundGroup.save();
    });
    const backgroundGroups = await Promise.all(promises);

    dispatch({
      type: hasParent ? 'REORDER_BACKGROUND_SUBGROUPS' : 'REORDER_BACKGROUND_GROUPS',
      [hasParent ? 'backgroundSubgroups' : 'backgroundGroups']: backgroundGroups.map((group) => group.toJSON()),
    });
  };
};
