import { Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';
import { getOverlayGroups, getOverlayGroupsByParent, reorderOverlayGroups } from '../../actions/overlayGroups';
import { deleteOverlay, editOverlay, getOverlaysPerPage, updateOverlay } from '../../actions/overlays';
import CustomTabPanel from '../../components/CustomTabPanel';
import ModalDialog from '../../components/ModalDialog';
import { validateOverlay } from '../../validation';
import OverlayDataGrid from './OverlayDataGrid';
import OverlayForm from './OverlayForm';

const OverlaysTable = () => {
  const dispatch = useDispatch();

  const overlays = useSelector((state) => state.overlays?.overlays);
  const overlay = useSelector((state) => state.overlays?.overlay);
  const count = useSelector((state) => state.overlays?.count);
  const overlayGroups = useSelector((state) => state.overlayGroups?.overlayGroups);
  const overlaySubgroups = useSelector((state) => state.overlayGroups?.overlaySubgroups);

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [filter, setFilter] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const [groups, setGroups] = useState([]);
  const [selectedGroupItem, setSelectedGroupItem] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [groupIndex, setGroupIndex] = useState(0);

  const [subgroups, setSubgroups] = useState([]);
  const [selectedSubgroupItem, setSelectedSubgroupItem] = useState(null);
  const [selectedSubgroupId, setSelectedSubgroupId] = useState(null);
  const [subgroupIndex, setSubgroupIndex] = useState(0);

  useEffect(() => {
    dispatch(getOverlayGroups(false));
  }, [dispatch]);

  useEffect(() => {
    const groups = overlayGroups?.map((group) => ({ id: group.objectId, title: group.name }));
    setGroups(groups);
    setSelectedGroupItem(groups[groupIndex]);
    setSelectedGroupId(groups[groupIndex]?.id);
  }, [groupIndex, overlayGroups]);

  useEffect(() => {
    if (selectedGroupId) {
      dispatch(getOverlayGroupsByParent(selectedGroupId));
    }
  }, [dispatch, selectedGroupId]);

  useEffect(() => {
    if (overlaySubgroups?.length > 0) {
      const subgroups = overlaySubgroups.map((group) => ({ id: group.objectId, title: group.name }));
      setSubgroups(subgroups);
      setSelectedSubgroupItem(subgroups[subgroupIndex]);
      setSelectedSubgroupId(subgroups[subgroupIndex]?.id);
    }
  }, [overlaySubgroups, subgroupIndex]);

  useEffect(() => {
    dispatch(getOverlaysPerPage(limit, skip, filter, selectedSubgroupId));
  }, [dispatch, filter, limit, skip, selectedSubgroupId]);

  const formatData = useCallback(() => {
    return {
      data: overlays.map((overlay) => ({
        objectId: overlay.objectId,
        image: 'https://storage.cloud.google.com/silhouette-users/overlays/' + overlay.image,
        name: overlay.name,
        description: overlay.description,
      })),
      totalCount: count,
    };
  }, [overlays, count]);

  const onGroupSelectionChanged = useCallback((args) => {
    if (args.addedItems.length > 0) {
      const selected = args.addedItems[0];
      setSelectedGroupItem(selected);
      setSelectedGroupId(selected?.id);
    }
  }, []);

  const onSubgroupSelectionChanged = useCallback((args) => {
    if (args.addedItems.length > 0) {
      const selected = args.addedItems[0];
      setSelectedSubgroupItem(selected);
      setSelectedSubgroupId(selected.id);
    }
  }, []);

  const onGroupTabDragStart = useCallback((e) => {
    e.itemData = e.fromData[e.fromIndex];
  }, []);

  const onGroupTabDrop = useCallback(
    (e) => {
      if (!e.itemData || groups.length === 0) return;
      const newGroup = [...groups];
      newGroup.splice(e.fromIndex, 1);
      newGroup.splice(e.toIndex, 0, e.itemData);
      const newIndex = newGroup.findIndex((group) => group.id === selectedGroupId);
      setGroupIndex(newIndex);
      setGroups(newGroup);
      dispatch(reorderOverlayGroups(newGroup, false));
    },
    [dispatch, groups, selectedGroupId]
  );

  const onSubgroupTabDragStart = useCallback((e) => {
    e.itemData = e.fromData[e.fromIndex];
  }, []);

  const onSubgroupTabDrop = useCallback(
    (e) => {
      if (!e.itemData || subgroups.length === 0) return;
      const newSubgroup = [...subgroups];
      newSubgroup.splice(e.fromIndex, 1);
      newSubgroup.splice(e.toIndex, 0, e.itemData);
      const newIndex = newSubgroup.findIndex((group) => group.id === selectedSubgroupId);
      setSubgroupIndex(newIndex);
      setSubgroups(newSubgroup);
      dispatch(reorderOverlayGroups(newSubgroup, true));
    },
    [subgroups, dispatch, selectedSubgroupId]
  );

  const handleOptionChange = useCallback(
    (e) => {
      if (e.fullName === 'searchPanel.text') {
        setFilter(e.value);
      }
      if (e.fullName === 'paging.pageSize') {
        setLimit(e.value);
      }
      if (e.fullName === 'paging.pageIndex') {
        setSkip(e.value * limit);
      }
    },
    [limit]
  );

  const handleDelete = (id) => {
    dispatch(deleteOverlay(id));
  };

  const handleEdit = (id) => {
    dispatch(editOverlay(id));
    setOpenDialog(true);
  };

  const handleUpdate = async (values) => {
    validateOverlay(values);
    await dispatch(updateOverlay(overlay, values));
    handleClose();
  };

  const handleClose = () => setOpenDialog(false);

  const handleSubmit = () => dispatch(submit('overlayForm'));

  const renderTitle = (item) => {
    return <Typography>{item?.title}</Typography>;
  };

  return (
    <>
      <div style={{ maxHeight: '100vh', overflowY: 'auto', scrollbarWidth: 'none' }}>
        <CustomTabPanel
          tabs={groups}
          selectedItem={selectedGroupItem}
          onSelectionChanged={onGroupSelectionChanged}
          onTabDragStart={onGroupTabDragStart}
          onTabDrop={onGroupTabDrop}
          renderTitle={renderTitle}
        >
          <CustomTabPanel
            tabs={subgroups}
            selectedItem={selectedSubgroupItem}
            onSelectionChanged={onSubgroupSelectionChanged}
            onTabDragStart={onSubgroupTabDragStart}
            onTabDrop={onSubgroupTabDrop}
            renderTitle={renderTitle}
          >
            <OverlayDataGrid onFormatData={formatData} onEdit={handleEdit} onDelete={handleDelete} onChange={handleOptionChange} />
          </CustomTabPanel>
        </CustomTabPanel>
      </div>

      <ModalDialog
        name={`Modifier ${overlay?.name}`}
        content={<OverlayForm onSubmit={handleUpdate} />}
        isVisible={openDialog}
        onConfirm={handleSubmit}
        onClose={handleClose}
        labelConfirm="Enregistrer"
      />
    </>
  );
};

export default OverlaysTable;
