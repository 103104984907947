import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const customStyles = {
  container: (styles, state) => {
    const {
      selectProps: { width, fullWidth },
    } = state;

    return {
      ...styles,
      width: fullWidth ? '100%' : width,
      marginTop: 10,
    };
  },
  control: (styles, state) => {
    const {
      selectProps: { width, fullWidth },
      isFocused,
    } = state;
    return {
      ...styles,
      width: fullWidth ? '100%' : width,
      outline: isFocused ? '1px solid #fff' : 'none',
      border: 'none',
      borderRadius: 0,
      borderBottom: '1px solid #949494',
      ':hover': {
        outline: '1px solid #fff',
        borderBottom: '1px solid #949494',
      },
    };
  },
  menu: () => ({
    zIndex: 100,
  }),
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? '#fff' : 'blue',
    padding: '14px 20px',
    width: '100%',
  }),
  singleValue: (styles, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...styles, opacity, transition };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      padding: '3px 5px 3px 8px',
      fontSize: 18,
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    marginLeft: 8,
    ':hover': {
      opacity: 0.5,
      cursor: 'pointer',
    },
  }),
};

const ReduxFormReactCreatableSelect = (props) => {
  const {
    input,
    options,
    isClearable,
    isLoading,
    isMulti,
    meta: { touched, error },
    fullWidth,
    onCreateOption,
    styles,
    ...otherProps
  } = props;

  return (
    <>
      <CreatableSelect
        {...input}
        isClearable={isClearable}
        isLoading={isLoading}
        isMulti={isMulti}
        options={options}
        fullWidth
        styles={styles || customStyles}
        onChange={(value) => input.onChange(value)}
        onBlur={() => input.onBlur(input.value)}
        onCreateOption={(value) => onCreateOption(value, input)}
        {...otherProps}
      />
      {touched && error && <span>{error}</span>}
    </>
  );
};

ReduxFormReactCreatableSelect.propTypes = {
  input: PropTypes.any,
  options: PropTypes.any,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onCreateOption: PropTypes.func,
};

export default ReduxFormReactCreatableSelect;
