import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { createOverlayGroup, getOverlayGroups } from '../../actions/overlayGroups';
import DropFileUploader from '../../components/form/DropFileUploader';
import FormField from '../../components/form/FormField';
import ReduxFormReactCreatableSelect from '../../components/reduxFormMUIWrapper/ReduxFormReactCreatableSelect';

const useStyles = makeStyles({
  root: {
    composes: 'flexColumn stretch',
  },
  row: {
    composes: 'flexRow spaceAround',
    width: '100%',
  },
});

const formatOptions = (items) =>
  items?.map((item) => ({
    label: item.name,
    value: item.objectId,
    item,
  }));

let OverlayForm = (props) => {
  const { handleSubmit } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const [groupLoading, setGroupLoading] = useState(false);
  const [subGroupLoading, setSubGroupLoading] = useState(false);

  const overlayGroups = useSelector((state) => state.overlayGroups?.overlayGroups);
  const overlaySubgroups = useSelector((state) => state.overlayGroups?.overlaySubgroups);

  // get the value of the group field from the form to use it in the subgroups field
  const selector = formValueSelector('overlayForm');
  const group = useSelector((state) => selector(state, 'group'));
  const subgroups = overlaySubgroups?.filter((subgroup) => subgroup.parent.objectId === group?.value);

  useEffect(() => {
    (() => {
      dispatch(getOverlayGroups(false));
      dispatch(getOverlayGroups(true));
    })();
  }, [dispatch]);

  const handleCreateOption = async (value, input, hasParent = false) => {
    let values = { name: value };
    if (hasParent) {
      values = { ...values, parent: group.value };
    }
    hasParent ? setSubGroupLoading(true) : setGroupLoading(true);
    const overlayGroup = await dispatch(createOverlayGroup(values, hasParent));
    hasParent ? setSubGroupLoading(false) : setGroupLoading(false);
    input.onChange({ label: overlayGroup.name, value: overlayGroup.objectId });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <FormField
        label="Groupe"
        name="group"
        isClearable
        fullWidth
        options={formatOptions(overlayGroups)}
        component={ReduxFormReactCreatableSelect}
        onCreateOption={handleCreateOption}
        isLoading={groupLoading}
      />
      <FormField
        label="Sous groupe"
        name="subgroup"
        isClearable
        fullWidth
        options={formatOptions(subgroups)}
        component={ReduxFormReactCreatableSelect}
        onCreateOption={(value, input) => handleCreateOption(value, input, true)}
        isLoading={subGroupLoading}
      />
      <FormField label="Nom" name="name" fullWidth />
      <FormField label="Description" name="description" fullWidth />
      <FormField label="Image" name="image" component={DropFileUploader} fullWidth />
      <input type="submit" />
    </form>
  );
};

OverlayForm.propTypes = {
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};

OverlayForm = reduxForm({
  form: 'overlayForm',
  enableReinitialize: true,
})(OverlayForm);

OverlayForm = connect((state) => {
  const { group, name, description, image } = state.overlays?.overlay || {};
  return {
    initialValues: {
      group: group ? { label: group.parent.name, value: group.parent.objectId } : null,
      subgroup: group ? { label: group.name, value: group.objectId } : null,
      name,
      description,
      image,
    },
  };
})(OverlayForm);

export default OverlayForm;
