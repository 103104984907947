import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { useUpdate } from 'react-use';

import { getGCPUrl } from '../../utils';

import TemplateForm, { ToTemplateProperties, valuesFromTemplate } from '../templates/TemplateForm';

import EditIconButton from '../../components/buttons/EditIconButton';
import ModalDialog from '../../components/ModalDialog';
import ImagePreview from '../../components/ImagePreview';


const useStyles = makeStyles((theme) => ({
	container: {
		composes: 'flexColumn stretch',
		padding: 10,
		maxHeight: '25%'
	},
	header: {
		composes: 'flexRow center',
	},
	title: {
		composes: 'flex1',
		fontSize: 18,
	},
	imgContainer: {
		composes: 'flexCenter',
		flex: 1,
		overflow: 'hidden',
	},
	img: {
		maxHeight: '100%',
		maxWidth: '100%',
	},
	dimensions: {
		color: 'rgba(0, 0, 0, 0.54)',
		paddingTop: 10,
		fontSize: 12,
	}
}));


const TemplateDetails = (props) => {
	//props
	const { template, onSave } = props;
	const [isOpenDialog, setIsOpenDialog] = useState(false);

	//dispatch
	const dispatch = useDispatch();
	//update
	const update = useUpdate();

	const _editTemplate = () => {
		setIsOpenDialog(true);
	}
	const handleCloseDialog = () => {
		setIsOpenDialog(false);
	}

	const _save = async values => {
		const newValues = ToTemplateProperties(values);
		onSave(newValues);
		handleCloseDialog();
		update();
	}

	const _submit = () => {
		dispatch(submit('templateForm'));
	};

	const renderTemplateImage = () => {
		return <div className={classes.imgContainer}>
			{template.has('imageTmp') && <img alt="" className={classes.img} src={URL.createObjectURL(template.get('imageTmp'))} />}
			{template.get('imageId') && <img alt="" className={classes.img} src={`${getGCPUrl()}${template.id.toLowerCase()}/${template.get('imageId')}`} />}
		</div>
	}

	const formInitialValues = useMemo(() => valuesFromTemplate(template), [template]);

	//classes
	const classes = useStyles(props);
	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<div className={classes.title}>
					{template.get('name')}
					<span className={classes.dimensions}>{`(${template.getWidthInPx()}x${template.getHeightInPx()})`}</span>
				</div>
				<EditIconButton onEdit={_editTemplate} />
			</div>
			{renderTemplateImage()}
			<ModalDialog
				title={`Modifier ${template.get('name')}`}
				content={<TemplateForm onSubmit={_save} initialValues={formInitialValues} />}
				isVisible={isOpenDialog}
				onConfirm={_submit}
				onClose={handleCloseDialog}
			/>
		</div>
	);
}
export default TemplateDetails;