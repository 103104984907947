const initialState = {
  backgrounds: [],
  background: {},
  count: 0,
};

export default function backgrounds(state = initialState, action) {
  switch (action.type) {
    case 'GET_BACKGROUNDS':
      return {
        ...state,
        backgrounds: action.backgrounds,
        count: action.count,
      };
    case 'ADD_BACKGROUND':
      return {
        ...state,
        backgrounds: [action.payload, ...state.backgrounds],
      };
    case 'DELETE_BACKGROUND':
      return {
        ...state,
        backgrounds: state.backgrounds.filter((background) => background.objectId !== action.id),
      };
    case 'EDIT_BACKGROUND':
      return {
        ...state,
        background: action.background,
      };
    case 'UPDATE_BACKGROUND':
      return {
        ...state,
        backgrounds: state.backgrounds.map((background) => {
          if (background.objectId === action.background.objectId) {
            return action.background;
          }
          return background;
        }),
        background: action.background,
      };
    default:
      return state;
  }
}
