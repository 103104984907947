import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { createOverlay } from '../../actions/overlays';
import AddIconButton from '../../components/buttons/AddIconButton';
import CustomCard from '../../components/CustomCard';
import ModalDialog from '../../components/ModalDialog';
import { validateOverlay } from '../../validation';
import OverlayForm from './OverlayForm';
import OverlaysTable from './OverlaysTable';

const Overlays = () => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleCreate = async (values) => {
    validateOverlay(values);
    await dispatch(createOverlay(values));
    handleCloseDialog();
  };

  const handleSubmit = () => dispatch(submit('overlayForm'));

  return (
    <>
      <CustomCard
        title="Liste des overlays"
        actionHeaderButtons={<AddIconButton onAdd={handleOpenDialog} />}
        content={<OverlaysTable />}
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
        title="Ajout nouveau overlay"
        content={<OverlayForm onSubmit={handleCreate} />}
        isVisible={openDialog}
        onConfirm={handleSubmit}
        onClose={handleCloseDialog}
      />
    </>
  );
};

Overlays.propTypes = {
  overlays: PropTypes.array,
};

export default Overlays;
