import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import ModalDialog from '../../components/ModalDialog';
import AddIconButton from '../../components/buttons/AddIconButton';

import TemplateForm, { ToTemplateProperties } from './TemplateForm';
import TemplatesTable from './TemplatesTable';

import { createTemplate } from '../../actions/templates';
import { getTemplates } from '../../reducers/templates';

const initialValues = {
  backgroundColor: '#ffffff',
  transparent: true,
};

const Templates = () => {
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const dispatch = useDispatch();

  const templates = useSelector(getTemplates) || [];

  const _openDialog = () => setOpen(true);
  const _closeDialog = () => setOpen(false);

  // save change
  const _createTemplate = async (values) => {
    const newValues = ToTemplateProperties(values);
    await dispatch(createTemplate(newValues));
    _closeDialog();
    setReload(!!!reload);
  };

  // form submit
  const _submit = async () => {
    dispatch(submit('templateForm'))
  };

  return (
    <>
      <CustomCard
        title="Liste des templates"
        content={<TemplatesTable isReloaded={reload} onReload={setReload} rows={templates} />}
        actionHeaderButtons={<AddIconButton onAdd={_openDialog} />}
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
        title="Ajouter nouveau template"
        content={<TemplateForm onSubmit={_createTemplate} initialValues={initialValues} />}
        isVisible={open}
        onConfirm={_submit}
        onClose={_closeDialog}
      />
    </>
  );
};

Templates.propTypes = {
  templates: PropTypes.array,
};

export default Templates;
