const initialState = {
  overlayGroups: [],
  overlaySubgroups: [],
};

const overlayGroups = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OVERLAY_GROUPS':
      return {
        ...state,
        overlayGroups: action.overlayGroups,
      };
    case 'ADD_OVERLAY_GROUP':
      return {
        ...state,
        overlayGroups: [action.payload, ...state.overlayGroups],
      };
    case 'GET_OVERLAY_SUBGROUPS':
      return {
        ...state,
        overlaySubgroups: action.overlaySubgroups,
      };
    case 'ADD_OVERLAY_SUBGROUP':
      return {
        ...state,
        overlaySubgroups: [action.payload, ...state.overlaySubgroups],
      };
    case 'REORDER_OVERLAY_GROUPS':
      return {
        ...state,
        overlayGroups: action.overlayGroups,
      };
    case 'REORDER_OVERLAY_SUBGROUPS':
      return {
        ...state,
        overlaySubgroups: action.overlaySubgroups,
      };
    default:
      return state;
  }
};

export default overlayGroups;
