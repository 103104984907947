import axios from 'axios';
import Parse from 'parse';
import { getUrl } from '../utils';
import { actionWithLoader, push } from './utils';
import { BackgroundGroup } from './backgroundGroups';

const Background = Parse.Object.extend('Background');

export const getBackgroundsPerPage = (limit = 10, skip = 0, filter = '', subgroupId) => {
  return async (dispatch) => {
    const query = new Parse.Query(Background)
      .include('group')
      .notEqualTo('deleted', true)
      .doesNotExist('template') // TODO: remove this line when old backgrounds are removed
      .limit(limit)
      .skip(skip)
      .descending('updatedAt')
      .withCount(true);

    if (filter !== '') {
      query.contains('title', filter);
    }

    if (subgroupId) {
      const group = new BackgroundGroup();
      group.id = subgroupId;
      query.equalTo('group', group);
    }

    const backgrounds = await query.find();

    dispatch({
      type: 'GET_BACKGROUNDS',
      backgrounds: backgrounds.results.map((background) => background.toJSON()),
      count: backgrounds.count,
    });
  };
};

export const createBackground = (values) => {
  return actionWithLoader(async (dispatch) => {
    const newValues = { ...values };

    // #1 - Create background
    const background = new Background();
    background.set('title', newValues.title);
    background.set('description', newValues.description);

    if (newValues.subgroup) {
      const backgroundSubGroup = new BackgroundGroup();
      backgroundSubGroup.id = newValues.subgroup.value;
      background.set('group', backgroundSubGroup);
    }

    // #2 - Upload image
    if (newValues.image instanceof File) {
      const formData = new FormData();
      formData.append('file', newValues.image);
      const API_SERVER_URL = getUrl() + '/api';
      const response = await axios.post(API_SERVER_URL + '/uploadBackground', formData);
      if (response.data) {
        background.set('image', response.data.fileName);
      }
    }

    await background.save();

    // #3 - Update store
    dispatch({
      type: 'ADD_BACKGROUND',
      payload: background.toJSON(),
    });
  });
};

export const deleteBackground = (id) => {
  return actionWithLoader(async (dispatch) => {
    // #1 - Remove background in database
    const background = await new Parse.Query(Background).get(id);
    if (!background) {
      throw new Error('Background not found');
    }
    background.set('deleted', true);
    await background.save();

    // #2 - Update store
    dispatch({
      type: 'DELETE_BACKGROUND',
      id,
    });
  });
};

export const editBackground = (id) => {
  return async (dispatch) => {
    const background = await new Parse.Query(Background).include('group').get(id);
    if (!background) {
      throw new Error('Background not found');
    }

    dispatch({
      type: 'EDIT_BACKGROUND',
      background: background.toJSON(),
    });
  };
};

export const updateBackground = (background, values) => {
  return actionWithLoader(async (dispatch) => {
    const newValues = { ...values };

    const updatedBackground = new Background();
    updatedBackground.id = background.objectId;

    updatedBackground.set('title', newValues.title);
    updatedBackground.set('description', newValues.description);

    if (newValues.image instanceof File) {
      const formData = new FormData();
      formData.append('file', newValues.image);

      const API_SERVER_URL = getUrl() + '/api';
      const response = await axios.post(API_SERVER_URL + '/uploadBackground', formData);
      if (response.data) {
        updatedBackground.set('image', response.data.fileName);
      }
    }

    if (newValues.subgroup) {
      const backgroundSubGroup = new BackgroundGroup();
      backgroundSubGroup.id = newValues.subgroup.value;
      updatedBackground.set('group', backgroundSubGroup);
    }

    await updatedBackground.save();

    dispatch({
      type: 'UPDATE_BACKGROUND',
      background: updatedBackground.toJSON(),
    });
  });
};

//--------------------------------------------------------//
//-------------------- Redirection -----------------------//
//--------------------------------------------------------//
export function goToBackgrounds() {
  return push('/backgrounds');
}
