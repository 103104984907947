const initialState = {
  backgroundGroups: [],
  backgroundSubgroups: [],
};

const backgroundGroups = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_BACKGROUND_GROUPS':
      return {
        ...state,
        backgroundGroups: action.backgroundGroups,
      };
    case 'ADD_BACKGROUND_GROUP':
      return {
        ...state,
        backgroundGroups: [action.payload, ...state.backgroundGroups],
      };
    case 'GET_BACKGROUND_SUBGROUPS':
      return {
        ...state,
        backgroundSubgroups: action.backgroundSubgroups,
      };
    case 'ADD_BACKGROUND_SUBGROUP':
      return {
        ...state,
        backgroundSubgroups: [action.payload, ...state.backgroundSubgroups],
      };
    case 'REORDER_BACKGROUND_GROUPS':
      return {
        ...state,
        backgroundGroups: action.backgroundGroups,
      };
    case 'REORDER_BACKGROUND_SUBGROUPS':
      return {
        ...state,
        backgroundSubgroups: action.backgroundSubgroups,
      };
    default:
      return state;
  }
};

export default backgroundGroups;
