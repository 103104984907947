import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { createBackground } from '../../actions/backgrounds';
import AddIconButton from '../../components/buttons/AddIconButton';
import CustomCard from '../../components/CustomCard';
import ModalDialog from '../../components/ModalDialog';
import { validateBackground } from '../../validation';
import BackgroundForm from './BackgroundForm';
import BackgroundsTable from './BackgroundsTable';

const Backgrounds = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const handleCreate = async (values) => {
    validateBackground(values);
    await dispatch(createBackground(values));
    closeDialog();
  };

  const handleSubmit = () => dispatch(submit('backgroundForm'));

  return (
    <>
      <CustomCard
        title="Liste des fonds"
        actionHeaderButtons={<AddIconButton onAdd={openDialog} />}
        content={<BackgroundsTable />}
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
        title="Ajout nouveau fond"
        content={<BackgroundForm onSubmit={handleCreate} />}
        isVisible={open}
        onConfirm={handleSubmit}
        onClose={closeDialog}
      />
    </>
  );
};

Backgrounds.propTypes = {
  backgrounds: PropTypes.array,
};

export default Backgrounds;
