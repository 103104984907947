import React from 'react';
import { useStore } from 'react-redux';
import { IndexRoute, Route, Router } from 'react-router';

import App from './containers/App';
import Home from './containers/Home';

import Login from './containers/login/Login';

import OldTemplateEditor from './containers/templateEditor/OldTemplateEditor';
import TemplateEditor from './containers/templateEditor/TemplateEditor';
import TemplateCreation from './containers/templates/TemplateCreation';
import Templates from './containers/templates/Templates';

import MontageCreation from './containers/montages/MontageCreation';
import MontageEdit from './containers/montages/MontageEdit';
import MontagePreview from './containers/montages/MontagePreview';
import Montages from './containers/montages/Montages';

import Products from './containers/products/Products';

import Test from './containers/test/Test';

import ThemeCreation from './containers/themes/ThemeCreation';
import ThemeEdit from './containers/themes/ThemeEdit';
import ThemePreview from './containers/themes/ThemePreview';
import Themes from './containers/themes/Themes';

import { onEnterApp, onEnterHome, onEnterUnknownRoute } from './actions/app';
import { onEnterMontage } from './actions/montages';
import { onEnterTemplate } from './actions/templates';

import { onEnterTheme, onEnterThemes } from './actions/themes';

import Orders from './containers/orders/Orders';

import { onEnterPromoBanners } from './actions/promoBanners';
import { onEnterEndPointTest } from './actions/test';
import Backgrounds from './containers/backgrounds/Backgrounds';
import OrdersUpdate from './containers/orders/OrdersUpdate';
import PromoBanners from './containers/promoBanners/PromoBanners';
import EndPointTest from './containers/test/EndPointTest';
import Overlays from './containers/overlays/Overlays';

const Routes = (props) => {
  const store = useStore();
  const { history } = props;

  return (
    <Router history={history}>
      <Route path="/" component={App} onEnter={onEnterApp(store)}>
        <IndexRoute component={Home} onEnter={onEnterHome(store)} />
        <Route path="login" component={Login} />

        <Route path="templates" component={Templates} />
        <Route path="templateCreation" component={TemplateCreation} />
        <Route path="oldtemplate-:templateId" component={OldTemplateEditor} onEnter={onEnterTemplate(store)} />
        <Route path="template-:templateId" component={TemplateEditor} onEnter={onEnterTemplate(store)} />

        <Route path="montageCreation" component={MontageCreation} />
        <Route path="montages" component={Montages} />
        <Route path="montageEdit-:montageId" component={MontageEdit} onEnter={onEnterMontage(store)} />
        <Route path="montage-:montageId" component={MontagePreview} onEnter={onEnterMontage(store)} />

        <Route path="themeCreation" component={ThemeCreation} />
        <Route path="themes" component={Themes} onEnter={onEnterThemes(store)} />
        <Route path="themeEdit-:themeId" component={ThemeEdit} onEnter={onEnterTheme(store)} />
        <Route path="theme-:themeId" component={ThemePreview} onEnter={onEnterTheme(store)} />

        <Route path="products" component={Products} />
        <Route path="orders" component={Orders} />
        <Route path="promoBanners" component={PromoBanners} onEnter={onEnterPromoBanners(store)} />

        <Route path="test" component={Test} />

        <Route path="updateOrderAssets" component={OrdersUpdate} />

        <Route path="endPointImageTest-:imageId" component={EndPointTest} onEnter={onEnterEndPointTest(store)} />

        <Route path="backgrounds" component={Backgrounds} />
        <Route path="overlays" component={Overlays} />

        <Route path="*" onEnter={onEnterUnknownRoute(store)} />
      </Route>
    </Router>
  );
};

export default Routes;
