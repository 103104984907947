import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import clone from 'lodash/clone';

import DeleteIconButton from './buttons/DeleteIconButton';
import LayerIcon from './LayerIcon';
import AddIconButton from './buttons/AddIconButton';
import VisibilityButton from './buttons/VisibilityButton';
import VisibilityOffButton from './buttons/VisibilityOffButton';

const useStyles = makeStyles((theme) => ({
	root: {
		composes: 'flexColumn stretch',
		width: '100%',
	},
	container: {
		composes: 'flexRow center',
		padding: '5px 2px 0 12px'
	},
	content: {
		composes: 'flex1',
		display: 'flex',
		alignItems: 'center'
	},
	deleteIcon: {
		zIndex: 1
	},
	lastItem: {
		marginBottom: '0 !important'
	},
	selected: {
		backgroundColor: `${theme.background.blue[570]} !important`
	}
}));
const gap = 10;

const getItemStyle = (isDragging, draggableStyle, isDragStart = true) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	margin: `0 0 ${gap}px 0`,

	// change background colour if dragging
	background: isDragging ? 'lightgreen' : 'azure',

	opacity: isDragStart ? 0.5 : 1,

	// styles we need to apply on draggable
	...draggableStyle
});

const getListStyle = (isDraggingOver, isDragStart) => ({
	background: isDraggingOver ? 'lightblue' : 'lightgrey',
	display: isDragStart ? 'none' : 'block',
	padding: gap,
	paddingRight: 0,
	width: '100%'
});


const OrderableStackChildren = (props) => {

	const classes = useStyles(props);

	const {
		type,
		items,
		maskId,
		onRemoveItemMask,
		onOrderItemsMask,
		onSelect,
		onAddItems,
		selectedItem,
		isDragStart = false,
		idProvider = item => item.id,
		contentProvider = item => item.name,
		toggleVisibility
	} = props;

	const reversedItems = clone(items).reverse();

	const toNonReversedItems = reversedIndex => items.length - 1 - reversedIndex;



	const onDragEnd = (result) => {

		if (!result.destination) {
			return;
		}

		//---- really moved ----//
		if (result.source.index === result.destination.index) {
			// not moved
			return;
		}
		onOrderItemsMask(
			maskId,
			toNonReversedItems(result.source.index),
			toNonReversedItems(result.destination.index)
		);
	}
	const _onShowLayer = (event, item, index) => {
		event.stopPropagation();
		toggleVisibility(item, true);
	}

	const _onHideLayer = (event, item) => {
		event.stopPropagation();
		toggleVisibility(item, false);
	}

	const _onItemSelect = (item) => {

		onSelect(item);
	}

	const _onDeleteItem = (event, parentMaskId, index) => {
		event.stopPropagation();
		onRemoveItemMask(parentMaskId, toNonReversedItems(index));
	}



	return (
		<DragDropContext onDragEnd={onDragEnd} >
			<Droppable
				droppableId={type}
				type={type}
				onDragEnd={(result) => { console.log(result) }}
			>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver, isDragStart)}
					>
						{reversedItems.map((item, index) => (
							<Draggable key={idProvider(item)} draggableId={idProvider(item)} index={index}>
								{(provided, snapshot) => (
									<div style={{ display: "" }}>
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style,
												isDragStart
											)}
											className={
												classNames(
													classes.container, {
													[classes.lastItem]: index === items.length - 1,
													[classes.selected]: item.id === selectedItem?.id
												})
											}

											onClick={() => _onItemSelect(item)}
										>

											<div
												className={classes.content}
											>
												<LayerIcon type={item.type} />
												{contentProvider(item)}
											</div>
											{(item.type === 'mask') && (
												<AddIconButton className={classes.button} onAdd={(event) => { onAddItems(event, item) }} />
											)}
											{!item.hide && <VisibilityButton className={classes.button} onShow={(event) => { _onShowLayer(event, item) }} />}
											{item.hide && <VisibilityOffButton className={classes.button} onHide={(event) => { _onHideLayer(event, item) }} />}

											<DeleteIconButton
												onDelete={(event) => { _onDeleteItem(event, maskId, index) }}
												color='secondary'
												className={classes.deleteIcon}
											/>

										</div>
										{(item.type === 'mask') && (
											<OrderableStackChildren
												type={idProvider(item)}
												maskId={item.id}
												maskIndex={toNonReversedItems(index)}
												items={item.layers}
												onOrderItemsMask={onOrderItemsMask}
												onRemoveItemMask={onRemoveItemMask}
												onAddItems={onAddItems}
												onSelect={onSelect}
												selectedItem={selectedItem}
												toggleVisibility={toggleVisibility}
											/>
										)}
										{provided.placeholder}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);

}

export default OrderableStackChildren;
