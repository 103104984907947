import { SubmissionError } from 'redux-form';

/**
 * form validation
 * @param error
 * @param name
 * @param message
 * @return void
 */
const validate = ({ error, name, message }) => {
  if (error) {
    throw new SubmissionError({
      [name]: message,
    });
  }
};

/**
 * field array validation for product
 * @param values
 * @param withIcon
 * @return void
 */
const validateProductFieldArray = (values, field, withTitle = true) => {
  if (!values) return;

  values.forEach((_, index) => {
    // title field
    if (withTitle) {
      if (!values[index].title) {
        throw new SubmissionError({
          [field]: {
            [index]: {
              title: 'Titre requis',
            },
          },
        });
      }
    }

    // description field
    if (!withTitle) {
      if (!values[index].desc) {
        throw new SubmissionError({
          [field]: {
            [index]: {
              desc: 'Description requis',
            },
          },
        });
      }
    }

    // icon field
    if (!values[index].icon) {
      throw new SubmissionError({
        [field]: {
          [index]: {
            icon: 'Icon requis',
          },
        },
      });
    }
  });
};

/**
 * project form validation
 * @param {*} values
 * @return string url
 */
export const validateProductCustomFields = (values) => {
  // detail validation
  validateProductFieldArray(values.details, 'details');

  // shipping validation
  validateProductFieldArray(values.shipping, 'shipping');

  // ideal for validation
  validateProductFieldArray(values.idealFor, 'idealFor', false);
};

/**
 * montage form validation
 * @param {*} values
 */
export const validateMontage = (values) => {
  validate({
    error: !values.template,
    name: 'template',
    message: 'Template required',
  });
};

/**
 * theme form validation
 * @param {*} values
 */
export const validateTheme = (values) => {
  validate({
    error: !values.name,
    name: 'name',
    message: 'Name required',
  });
};

/**
 * theme form validation
 * @param {*} values
 */
export const validateTemplateLayer = (layer) => {
  if (layer.imageFile) {
    validate({
      error: !['image/png', 'image/jpeg'].includes(layer.imageFile.type),
      name: 'imageFile',
      message: 'Only png and jpg are accepted',
    });
  }
};

/**
 * background form validation
 * @param {*} values
 */
export const validateBackground = (values) => {
  validate({
    error: !values.group,
    name: 'group',
    message: 'Group required',
  });

  validate({
    error: !values.subgroup,
    name: 'subgroup',
    message: 'Subgroup required',
  });

  validate({
    error: !values.title,
    name: 'title',
    message: 'Title required',
  });

  validate({
    error: !values.description,
    name: 'description',
    message: 'Description required',
  });
};

/**
 * overlay form validation
 *
 * @param {*} values
 */
export const validateOverlay = (values) => {
  validate({
    error: !values.group,
    name: 'group',
    message: 'Group required',
  });

  validate({
    error: !values.subgroup,
    name: 'subgroup',
    message: 'Subgroup required',
  });

  validate({
    error: !values.name,
    name: 'name',
    message: 'Name required',
  });

  validate({
    error: !values.description,
    name: 'description',
    message: 'Description required',
  });
};

/**
 * background form validation
 * @param {Object} values
 * @param {Boolean} isUpdate
 */
export const validatePromoBanner = (values, isUpdate = false) => {
  validate({
    error: !values.title,
    name: 'title',
    message: 'Title required',
  });

  validate({
    error: !values.desc,
    name: 'desc',
    message: 'Description required',
  });

  if (!isUpdate) {
    validate({
      error: !values.image,
      name: 'image',
      message: 'Image required',
    });
  }
};
