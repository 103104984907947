import { memo, useEffect, useRef } from 'react';
import { Image, Layer, Rect, Transformer } from 'react-konva';
import { addMarginToCanvas, getPathImageGCP, layerToCanvasProps } from '../../../utils';
import useImage from 'use-image';

const keyDownHandler = (e, edit) => {
  const moveSpeed = Math.max(1, Math.round(edit.width / 200), Math.round(edit.height / 200));
  let newTop = edit.top;
  let newLeft = edit.left;

  switch (e.key) {
    case 'ArrowLeft':
      newLeft -= moveSpeed;
      break;
    case 'ArrowRight':
      newLeft += moveSpeed;
      break;
    case 'ArrowUp':
      newTop -= moveSpeed;
      break;
    case 'ArrowDown':
      newTop += moveSpeed;
      break;
    default:
      return;
  }

  return { ...edit, top: newTop, left: newLeft };
};

const LayerEditor = ({
  layer,
  template,
  sizeRatio,
  onChange,
  onChangeStart = () => { },
  onChangeEnd = () => { }
}) => {
  const canvasPros = layerToCanvasProps(layer, sizeRatio);
  const shapeRef = useRef();
  const trRef = useRef();

  useEffect(() => {
    if (layer) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [layer, sizeRatio]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const newPosition = keyDownHandler(e, layer,);
      if (newPosition) onChange && onChange(newPosition);
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [layer]);


  const handleDragEnd = (e) => {
    onChange && onChange({
      ...layer,
      left: Math.round((e.target.x() - canvasPros.offsetX) / sizeRatio - layer.parentLeft),
      top: Math.round((e.target.y() - canvasPros.offsetY) / sizeRatio - layer.parentTop),
    });
    onChangeEnd();
  }

  const onTransformEnd = () => {
    const node = shapeRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    const x = node.x();
    const y = node.y();
    const nodeWidth = node.width();
    const nodeHeight = node.height();
    const changment = {
      left: Math.round(x / sizeRatio - nodeWidth * scaleX / (sizeRatio * 2) - layer.parentLeft),
      top: Math.round(y / sizeRatio - nodeHeight * scaleY / (sizeRatio * 2) - layer.parentTop),
      width: Math.max(5, nodeWidth * scaleX / sizeRatio),
      height: Math.max(nodeHeight * scaleY / sizeRatio),
      rotation: node.rotation(),
    };

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
    onChange && onChange({
      ...layer,
      ...changment,
      // rotation: node.rotation(),
    });
  }

  const Component = layer.type === 'background' ? Rect : Image;

  let ImageProps = {};
  const defaultImage = `${process.env.PUBLIC_URL}/${layer.type}${layer.type === 'userImage' && layer.withBackground ? '-T.png' : '.jpg'}`;
  const pathImageGCP = (['image', 'mask'].includes(layer.type) && layer.imageId) ? getPathImageGCP(template.id, layer.imageId) : defaultImage;
  let urlImage = layer?.imageTempUrl ?? pathImageGCP;
  const [image] = useImage(urlImage, 'anonymous');


  if (layer.type === 'mask') {
    ImageProps = {
      image: image,
    }
  }

  return <Layer
    {...addMarginToCanvas(canvasPros)}
  >
    <Component
      ref={shapeRef}
      {...canvasPros}
      {...ImageProps}
      opacity={0.5}
      draggable
      onDragEnd={handleDragEnd}
      onDragStart={onChangeStart}
      // onDragMove={onDragMove}
      onTransformEnd={onTransformEnd}
    />
    <Transformer
      ref={trRef}
      flipEnabled={false}
      boundBoxFunc={(oldBox, newBox) => {
        // limit resize
        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
          return oldBox;
        }
        return newBox;
      }}
    />
  </Layer>
};

export default memo(LayerEditor);