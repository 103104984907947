import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import OrderableStack from '../../../components/OrderableStack';
import AddIconButton from '../../../components/buttons/AddIconButton';

const useStyles = makeStyles((theme) => ({
	root: {
		composes: 'flexColumn stretch center',
		minWidth: 150,
		maxWidth: 300,
		minHeight: 200,
		overflow: 'hidden'
	},
	header: {
		composes: 'flexRow center',
		width: '100%',
		borderBottom: theme.border.grey[630]
	},
	title: {
		composes: 'flex1',
		fontSize: 18,
		padding: 10
	},
	item: {
		width: '100%',
		composes: 'flexCenter',
		cursor: 'pointer',
		height: 40,
		borderTop: theme.border.green[710],
		'&:hover': {
			backgroundColor: theme.background.blue[570]
		},
		'&:focus, &:active': {
			backgroundColor: theme.background.blue[585]
		}
	},
	selectedItem: {
		backgroundColor: theme.background.blue[585]
	},
	addButton: {
		background: theme.background.blue[590]
	},
	background: {
		composes: 'flex1 stretchSelf',
		flex: 'none'
	},
}));

const LayersList = (props) => {
	// styles
	const classes = useStyles(props);

	// props
	const {
		layers = [],
		onOpenAddDialog,
		onOrderChange,
		selectedLayer,
		onSelection,
		onDelete,
		onDeselection,
		onOrderItemsMask,
		onRemoveItemMask,
		toggleVisibility,
		onChangeUsedForPrint,
	} = props;

	const _onOpenDialog = (event) => {
		event.stopPropagation();
		onOpenAddDialog();
	}

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.title}>Calques</div>
				<AddIconButton onAdd={_onOpenDialog} color='default' />
			</div>
			<OrderableStack
				items={layers}
				onSelect={onSelection}
				selectedItem={selectedLayer}
				onDelete={onDelete}
				onDeselection={onDeselection}
				onOrderChange={onOrderChange}
				onOpenAddDialog={onOpenAddDialog}
				withDeleteButton
				onOrderItemsMask={onOrderItemsMask}
				onRemoveItemMask={onRemoveItemMask}
				toggleVisibility={toggleVisibility}
				onChangeUsedForPrint={onChangeUsedForPrint}
			/>
			<div className={classes.background} onClick={onDeselection} />
		</div>
	);
};

LayersList.propTypes = {
	layers: PropTypes.array,
	onOpenAddDialog: PropTypes.func,
	onOrderChange: PropTypes.func,
	selectedLayer: PropTypes.any,
	onSelection: PropTypes.func,
	onDelete: PropTypes.func,
	onDeselection: PropTypes.func,
};
export default LayersList;
