import { Group, Image, Layer, Rect, Text } from 'react-konva';
import { addMarginToCanvas, containBottom, cover, getPathImageGCP, layerToCanvasProps } from '../../../utils';
import useImage from 'use-image';
import { memo, useMemo } from 'react';

const withImageUrl = (Component) => ({ url, ...props }) => {
  const [image] = useImage(url, 'anonymous');
  return <Component {...props} image={image} />
}

const withMask = (Component) => (props) => {
  const { masks = [], layer } = props;
  const visibleMasks = masks.filter(mask => !mask.hide);
  return <Group>
    {
      layer && <Rect
        {...layerToCanvasProps(layer)}
        fill="black"
        listening={false}
      />
    }
    {
      visibleMasks.map((mask, index) => <ImageWithUrl
        {...layerToCanvasProps(mask)}
        key={mask.id}
        url={mask.url}
        listening={false}
        globalCompositeOperation={((index > 0) || layer) ? "source-in" : ""}
      />)
    }
    <Component
      {...props}
      globalCompositeOperation={((visibleMasks.length > 0) || layer) ? "source-in" : ''}
    />
  </Group>

}

const ImageWithUrl = memo(withImageUrl(Image));
const ImageWithMask = memo(withMask(Image));
const RectWithMask = memo(withMask(Rect));

const LayerTypeImage = ({ layer, template }) => {
  const defaultImage = `${process.env.PUBLIC_URL}/${layer.type}${layer.type === 'userImage' && layer.withBackground ? '-T.png' : '.jpg'}`;
  const pathImageGCP = (['image', 'mask'].includes(layer.type) && layer.imageId) ? getPathImageGCP(template.id, layer.imageId) : defaultImage;
  let urlImage = layer?.imageTempUrl ?? pathImageGCP;
  const [image] = useImage(urlImage, 'anonymous');
  const {
    offsetX,
    offsetY,
    width,
    height
  } = useMemo(() => {
    if (layer.type === 'userImage' || layer.type === 'image') {
      if (layer.type === 'userImage' && layer.withBackground)
        return containBottom(layer.width, layer.height, image?.naturalWidth ?? layer.width, image?.naturalHeight ?? layer.height);
      return cover(layer.width, layer.height, image?.naturalWidth ?? layer.width, image?.naturalHeight ?? layer.height);
    }
    return {
      offsetX: 0,
      offsetY: 0,
      width: layer.width,
      height: layer.height
    }
  }, [image, layer]);

  return <ImageWithMask
    image={image}
    {...layerToCanvasProps({ ...layer, width, height })}
    x={layer.left + layer.parentLeft + offsetX + width / 2}
    y={layer.top + layer.parentTop + offsetY + height / 2
    }
    listening={false}
    masks={layer.masks}
    layer={layer}
  />
}

const LayerTypeText = ({ layer }) => {

  return <Group>
    <Text
      {...layerToCanvasProps(layer)}
      text={layer.text}
      fontSize={layer.fontSize}
      fontFamily={layer.fontFamily}
      fill={layer.color}
      listening={false}
      align={layer.alignment}
    />
  </Group>
}

const LayerTypeBackground = ({ layer }) => {
  return <RectWithMask
    {...layerToCanvasProps(layer)}
    fill={layer.backgroundColor ?? "white"}
    listening={false}
    masks={layer.masks}
    layer={layer}
  />
}

const LayerPreviewer = ({ layer, template }) => {

  return <Layer
    {...addMarginToCanvas(layer)}
  >
    {['image', 'mask', 'userImage'].includes(layer.type) && <LayerTypeImage
      layer={{
        ...layer,
        imageTempUrl: layer?.imageTemp ? URL.createObjectURL(layer.imageTemp) : null
      }}
      template={template}
    />
    }
    {layer.type === 'background' && <LayerTypeBackground
      layer={layer}
      template={template}
    />
    }

    {layer.type === 'userText' && <LayerTypeText
      layer={layer}
      template={template}
    />
    }
  </Layer>
}

export default memo(LayerPreviewer);