import React from 'react';
import { create } from 'jss';
import preset from 'jss-preset-default';
import { MuiThemeProvider, createGenerateClassName, StylesProvider, createTheme } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';

// see :
// https://material-ui.com/guides/migration-v3/
// https://material-ui.com/styles/api/#main-content
// https://material-ui.com/styles/advanced/

//-------------------------------//
//-------------- JSS ------------//
//-------------------------------//
const jss = create(preset());

// very important for react-await-dialog
const generateClassName = createGenerateClassName();

//-------------------------------//
//------- contentLayout ---------//
//-------------------------------//
const theme = createTheme({
	palette: {
		primary: cyan,
		type: 'light'
	},
	typography: {
		useNextVariants: true,
	},
});

const CustomThemeProvider = (props) => {
	return (
		<StylesProvider jss={jss} generateClassName={generateClassName}>
			<MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
		</StylesProvider>
	);
};
export default CustomThemeProvider;