import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DataGrid, { Column, Grouping, LoadPanel, Pager, Paging, RemoteOperations, SearchPanel, Selection } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles({
  grid: {
    '& td div, td': {
      fontSize: 15,
      fontWeight: 400,
    },
    '& td': {
      padding: '6px 20px !important',
    },
    '& td .dx-button-content': {
      padding: 5,
      marginRight: 5,
      marginLeft: 5,
    },
  },
  selectableRow: {
    '& tr': {
      cursor: 'pointer',
    },
  },
});

const CustomDataGrid = (props) => {
  const {
    showBorders = true,
    showRowLines = true,
    actionRender,
    rowAlternationEnabled = true,
    defaultPageSize = 10,
    className,
    showColumnLines = true,
    onSelectionChanged,
    onOptionChanged,
    actionColumnWidth,
    pageSizes = [10, 25, 50, 100],
    children,
    onRowClick,
    invisibleInputSearch = false,
    reloadDataPagination,
    reloaded = false,
  } = props;

  const classes = useStyles();
  const [store, setStore] = useState([]);

  useEffect(() => {
    const store = new CustomStore({
      load: async (options) => {
        return await reloadDataPagination(options);
      },
    });
    setStore(store);
  }, [reloadDataPagination, reloaded]);

  return (
    <DataGrid
      dataSource={store}
      showBorders={showBorders}
      rowAlternationEnabled={rowAlternationEnabled}
      showRowLines={showRowLines}
      showColumnLines={showColumnLines}
      className={classNames(classes.grid, className, onRowClick && classes.selectableRow)}
      onSelectionChanged={onSelectionChanged}
      onRowClick={onRowClick}
      onOptionChanged={onOptionChanged}
      remoteOperations={true}
    >
      {onSelectionChanged && <Selection mode="multiple" />}
      {children}
      {actionRender && <Column width={actionColumnWidth || 80} dataField="Actions" type="buttons" cellRender={actionRender} />}
      <RemoteOperations sorting={true} paging={true} filtering={true} />
      <SearchPanel visible={invisibleInputSearch ? false : true} highlightCaseSensitive />
      <Grouping autoExpandAll={false} />
      <Pager allowedPageSizes={pageSizes} showPageSizeSelector showNavigationButtons={true} />
      <Paging defaultPageSize={defaultPageSize} />
      <LoadPanel enabled />
    </DataGrid>
  );
};

CustomDataGrid.propTypes = {
  showBorders: PropTypes.bool,
  showRowLines: PropTypes.bool,
  rowAlternationEnabled: PropTypes.bool,
  showColumnLines: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  actionRender: PropTypes.func,
  onSelectionChanged: PropTypes.func,
  onOptionChanged: PropTypes.func,
  className: PropTypes.string,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  children: PropTypes.node,
  onRowClick: PropTypes.func,
};

export default CustomDataGrid;
