import React from 'react';
import PropTypes from 'prop-types';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { IconButton } from '@material-ui/core';

const FitScreenButton = ({ onClick, color = 'primary', className }) => (
  <IconButton
    aria-label='add'
    onClick={onClick}
    color={color}
    className={className}
  >
    <AspectRatioIcon />
  </IconButton>
)

FitScreenButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  className: PropTypes.string
}
export default FitScreenButton;