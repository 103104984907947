import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';

const ReduxFormTrueFalseRadio = (props) => {
  const {
    input: { value, onChange },
    meta: { error },
    children,
    defaultValue = false,
    row = false,
  } = props;
  const optionValue = value || defaultValue;

  const _onChange = (event, value) => {
    onChange(value === 'true');
  };

  return (
    <div className="width100">
      <RadioGroup value={String(optionValue)} onChange={_onChange} children={children} row={row} />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};

export default ReduxFormTrueFalseRadio;
