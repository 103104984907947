import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Parse from 'parse';
import { uid } from 'uid';

import { getPromoBanners } from '../reducers/promoBanners';
import { showHome } from './app';
import { loadTemplatesThunk } from './templates';
import { actionWithLoader, onEnter, push } from './utils';

//--------------------------------------------------------//
//--------------------- CRUD actions ---------------------//
//--------------------------------------------------------//
/**
 * create or update a montage
 * @param values
 * @returns {*}
 */
export const createOrUpdatePromoBanner = (values) => {
  return actionWithLoader(async (dispatch, getState) => {
    const promoBanners = getPromoBanners(getState());

    const id = values.id;

    /** image url formatting */
    let image;
    let contentType;
    if (values.image) {
      if (id && typeof values.image === 'string') {
        image = values.image;
        contentType = values.contentType;
      } else {
        image = new Parse.File(uid(24), values.image);
        await image.save();
      }
    }

    /** upload the values */
    const newValues = {
      ...values,
      image: { data: image, contentType },
      descLong: draftToHtml(convertToRaw(values.descLong.getCurrentContent())), // convert the raw html to string
    };

    const newPromoBanner = await Parse.Cloud.run('createOrUpdatePromoBanner', { ...newValues });

    /** update the list */
    const results = id
      ? [...promoBanners.results.filter((p) => p.id !== newPromoBanner.id), newPromoBanner]
      : [...promoBanners.results, newPromoBanner];

    /** dispatch */
    dispatch({
      type: 'PROMOBANNER_LOADED',
      promoBanner: newPromoBanner,
    });
    dispatch({
      type: 'PROMOBANNERS_UPDATED',
      promoBanners: {
        ...promoBanners,
        count: id ? promoBanners : promoBanners + 1,
        results,
      },
    });
  });
};

/**
 * delete current promoBanner
 * @param promoBannerId
 * @returns {*}
 */
export const deletePromoBanner = (promoBannerId) => {
  return actionWithLoader(async (dispatch, getState) => {
    const promoBanners = getPromoBanners(getState());

    const deletedPromoBanner = await Parse.Cloud.run('deletePromoBanner', { id: promoBannerId });
    const newPromoBanners = promoBanners.results.filter((p) => p.id !== deletedPromoBanner.id);

    dispatch({
      type: 'PROMOBANNERS_UPDATED', // used in promoBanners list
      promoBanners: {
        ...newPromoBanners,
        count: promoBanners.count - 1,
        results: newPromoBanners,
      },
    });
  });
};

/**
 * load all promoBanners
 * @returns {Function}
 */
export const loadPromoBannersThunk = () => {
  return async (dispatch) => {
    const promoBanners = await Parse.Cloud.run('getPromoBanners');

    if (promoBanners) {
      dispatch({
        type: 'PROMOBANNERS_LOADED',
        promoBanners,
      });
    }
    return promoBanners;
  };
};

/**
 * onEnter promoBanners
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export const onEnterPromoBanners = (store) => {
  return onEnter({
    store,
    actionThunk: () => {
      return async (dispatch, getState) => {
        const promoBanners = await loadPromoBannersThunk({ limit: 500 })(dispatch, getState);
        if (!promoBanners) {
          showHome();
        }
        await loadTemplatesThunk()(dispatch, getState);
      };
    },
  });
};

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//

export const showPromoBanners = () => {
  return push('/promoBanners');
};
