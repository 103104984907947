import app from './app';
import backgroundGroups from './backgroundGroups';
import backgrounds from './backgrounds';
import montages from './montages';
import orders from './orders';
import overlays from './overlay';
import overlayGroups from './overlayGroups';
import products from './products';
import promoBanners from './promoBanners';
import templates from './templates';
import themes from './themes';

/**
 * used by selectors
 * @param state
 * @param path
 * @param [errorMessageIfNotFound]
 * @returns {*}
 */
export function getData(state, path, errorMessageIfNotFound) {
  let data;
  try {
    if (typeof state === 'function') {
      throw new Error('The state parameter must not be a function. The error is usually the usage of getState instead of getState(). Path is', path);
    }
    data = path.split('.').reduce((res, prop) => res[prop], state);
    if (errorMessageIfNotFound && data == null) {
      throw new Error(errorMessageIfNotFound);
    }
  } catch (error) {
    console.error(error);
    return null;
  }
  return data;
}

const appReducers = {
  app,
  templates,
  montages,
  themes,
  products,
  orders,
  promoBanners,
  backgrounds,
  backgroundGroups,
  overlays,
  overlayGroups,
};

export default appReducers;
