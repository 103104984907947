import { Column } from 'devextreme-react/data-grid';
import CustomDataGrid from '../../components/devExpressTable/CustomDataGrid';
import TableButtonsAction from '../../components/devExpressTable/TableButtonsAction';
import CustomCell from '../../components/devExpressTable/CustomCell';
import CustomImageCell from '../../components/devExpressTable/CustomImageCell';

const columns = ['objectId', 'image', 'title', 'description'];

const BackgroundDataGrid = ({ onFormatData, onEdit, onDelete, onChange }) => {
  const handleEdit = (id) => {
    onEdit(id);
  };
  const handleDelete = (id) => {
    onDelete(id);
  };
  const handleOptionChange = (e) => {
    onChange(e);
  };

  return (
    <CustomDataGrid
      actionColumnWidth={150}
      reloadDataPagination={onFormatData}
      showColumnLines={false}
      actionRender={(value) => (
        <TableButtonsAction
          onEdit={() => handleEdit(value.data.objectId)}
          onDelete={() => handleDelete(value.data.objectId)}
          label={value.data.title}
        />
      )}
      onOptionChanged={handleOptionChange}
    >
      <Column dataField={columns[1]} caption="Image" alignment="center" cellRender={({ data }) => <CustomImageCell value={data.image} />} />
      <Column
        dataField={columns[2]}
        caption="Titre"
        alignment="center"
        cellRender={({ data }) => <CustomCell value={data.title} alignment="center" />}
      />
      <Column
        dataField={columns[3]}
        caption="Description"
        alignment="center"
        cellRender={({ data }) => <CustomCell value={data.description} alignment="center" />}
      />
    </CustomDataGrid>
  );
};

export default BackgroundDataGrid;
