import { useState } from 'react';
import ModalDialog from '../../../components/ModalDialog';
import LayerCreationForm from './LayerCreationForm';
import { validateTemplateLayer } from '../../../validation';
import { fill, findIndex, nth, omit } from 'lodash';
import { uid } from 'uid';
import { useFileDefault } from '../../../hooks/useFileDefault';
import Parse from 'parse';
import { mapLayersRecursively } from '../../../utils';
import { useMemo } from 'react';
import { countLayersByType } from '../../../actions/templates';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

const ModalAddLayer = ({ isOpen, onClose, layers, idMaskActive, template, onAddLayer }) => {
  const [layerDialogTitle, setLayerDialogTitle] = useState('');
  const defaultImageLayer = useFileDefault();
  const defaultMaskLayer = useFileDefault('masque.png');
  const defaultUserImage = useFileDefault('userImage.jpg');
  const countLayerName = useMemo(() => countLayersByType(layers), [layers]);
  const dispatch = useDispatch();

  const _submitLayer = () => {
    dispatch(submit('layerForm'));
  };

  const findItemInLayers = (id) => {
    const indexSearch = findIndex(layers, (l) => l.id === id);

    return nth(layers, indexSearch);
  };

  const getRandomName = (type) => {
    return `${type.charAt(0).toUpperCase() + type.substring(1).toLowerCase()} ${countLayerName[type] + 1}`;
  };

  // initial values create layer
  const initValuesLayer = () => {
    // default layer width and height
    let width = template.getWidthInPx();
    let height = template.getHeightInPx();

    if (idMaskActive !== '') {
      const mask = findItemInLayers(idMaskActive);
      width = mask.width;
      height = mask.height;
    }

    return {
      type: 'image',
      name: getRandomName('image'),
      width: width,
      height: height,
      top: 0,
      left: 0,
      // default values for text layer
      size: 18,
      font: 'Montserrat',
      color: '#A9A9A9',
      alignment: 'center',
      text: 'Text',
      // default values for mask layer
      usedForPrint: true,
    };
  };

  // add selectedLayer
  const _addLayer = async (values) => {
    validateTemplateLayer(values);
    let newLayers = [];

    let newValues = values;

    // remove the initial values for userText and mask
    if (values.type !== 'userText') {
      const userTextFields = ['alignment', 'font', 'color', 'size', 'text'];
      newValues = omit(values, userTextFields);
    } else if (values.type !== 'mask') {
      const maskFields = ['usedForPrint'];
      newValues = omit(values, maskFields);
    }

    const layer = {
      id: uid(10),
      ...newValues,
    };

    // set  default image
    if (layer.type === 'image' && !layer.imageFile) {
      layer.imageFile = defaultImageLayer;
    }

    if (layer.type === 'mask' && !layer.imageFile) {
      layer.imageFile = defaultMaskLayer;
    }

    if (layer.type === 'userImage' && !layer.imageFile) {
      layer.imageFile = defaultUserImage;
    }

    // set parse file
    if (layer.imageFile) {
      layer.imageTemp = layer.imageFile;
    }

    // init layers list mask
    if (layer.type === 'mask') {
      layer.layers = [];
    }

    //set file to Parse.File
    if (layer.imageFile) {
      const testType = layer.imageFile instanceof Parse.File;
      if (!testType) {
        const parseFile = new Parse.File(layer.id, layer.imageFile);
        layer.imageFile = parseFile;
      }
    }

    // add new mask items
    if (idMaskActive !== '') {
      const prevLayers = [...layers];
      const indexActiveMask = findIndex(prevLayers, (l) => l.id === idMaskActive);
      //may be the mask has a parent mask
      if (indexActiveMask === -1) {
        newLayers = mapLayersRecursively(prevLayers, idMaskActive, (maskItem) => {
          maskItem.layers = [...maskItem.layers, layer];
          return maskItem;
        });
      } else {
        const maskItem = { layers: [], ...nth(prevLayers, indexActiveMask) };
        maskItem.layers.push(layer);
        fill(prevLayers, maskItem, indexActiveMask, indexActiveMask + 1);
        newLayers = prevLayers.map((l) => {
          if (l.id === maskItem.id) {
            return maskItem;
          }
          return l;
        });
      }
    } else {
      newLayers = [...layers, layer];
    }
    onAddLayer && onAddLayer(newLayers);
    return newLayers;
  };

  const handleSetTitleLayerDialog = (value) => setLayerDialogTitle(value);
  return (
    <ModalDialog
      title={`Nouveau calque ${layerDialogTitle}`}
      isVisible={isOpen}
      content={
        <LayerCreationForm
          onChangeLayerType={handleSetTitleLayerDialog}
          onSubmit={_addLayer}
          idMask={idMaskActive}
          initialValues={initValuesLayer()}
          countLayers={countLayerName}
          heightDefault={initValuesLayer().height}
        />
      }
      onClose={onClose}
      labelCancel="Fermer"
      onConfirm={_submitLayer}
      labelConfirm="Ajouter"
    />
  );
};

export default ModalAddLayer;
