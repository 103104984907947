import PropTypes from 'prop-types';
import React from 'react';

import CurrentUserIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import CategoryIcon from '@material-ui/icons/Category';
import DvrIcon from '@material-ui/icons/Dvr';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

const MenuItemIcon = (props) => {
  const { name } = props;

  let icon = null;
  switch (name) {
    case 'user':
      icon = <CurrentUserIcon />;
      break;
    case 'scan':
    case 'templates':
      icon = <ViewQuiltIcon />;
      break;
    case 'montages':
      icon = <AspectRatioIcon />;
      break;
    case 'backgrounds':
      icon = <PhotoLibraryIcon />;
      break;
    case 'overlays':
      icon = <CategoryIcon />;
      break;
    case 'products':
      icon = <BurstModeIcon />;
      break;
    case 'orders':
      icon = <ShoppingBasketIcon />;
      break;
    case 'promoBanners':
      icon = <BrandingWatermarkIcon />;
      break;
    case 'test':
      icon = <FileCopyIcon />;
      break;

    case 'updateOrderAssets':
      icon = <DvrIcon />;
      break;
    case 'logout':
      icon = <LogoutIcon />;
      break;
    default:
      break;
  }

  return icon;
};

MenuItemIcon.propTypes = {
  name: PropTypes.string,
};

export default MenuItemIcon;
