import React from 'react';
import { round } from 'lodash';
import PropTypes from 'prop-types';
import ZoomOutButton from '../../components/buttons/ZoomOutButton';
import AddIconButton from '../../components/buttons/AddIconButton';
import FitScreenButton from '../../components/buttons/FitScreenButton';
import FullSizeButton from '../../components/buttons/FullSizeButton';
const ZoomButtons = ({ value, onChange }) => {

  const zoomOut = () => {
    onChange && onChange(Math.max(value >= 10 ? 10 : 0, round(value - 0.1, 1)));
  };

  const zoomIn = () => {
    onChange && onChange(Math.min(value >= 10 ? 11.9 : 1.9, round(value + 0.1, 1)));
  };

  const fitScreen = () => {
    onChange && onChange(1);
  };

  const fullSize = () => {
    onChange && onChange(11);
  };

  return (
    <div>
      <FitScreenButton onClick={fitScreen} color='default' />
      <FullSizeButton onClick={fullSize} color='default' />
      <ZoomOutButton onRemove={zoomOut} color='default' />
      <AddIconButton onAdd={zoomIn} color='default' />
    </div>
  )
}

ZoomButtons.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  className: PropTypes.string,
}

export default ZoomButtons;